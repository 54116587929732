<template>
  <div class="container-fluid">
    <page-title :title="`System Settings`"></page-title>

    <div class="row row-sm">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h3>System Settings</h3>
            <hr />
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      settings: [],
      setting: {
        indemnity_cost: "",
      },
    };
  },
  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get("/api/v1/dashboard/settings").then((response) => {
        this.setting = response.data;
        this.$loader.stop()
      });
    },

    save() {
      this.$loader.start()
      this.$axios.post("/api/v1/dashboard/settings", this.setting).then(() => {
        this.$loader.stop()
      });
    },
  },
};
</script>
